import * as React from 'react'
import { Router } from '@reach/router'

import BlindContour from '@components/BlindContour'
import { RoomWrapper } from '@components/Room'
import { RoomType } from '@stores/models/Room'

const BlindPage = () => {
  return (
    <Router>
      <RoomWrapper path="/blind/:roomId" type={RoomType.BLIND}>
        <BlindContour path="/" />
      </RoomWrapper>
    </Router>
  )
}

export default BlindPage
