import { CatmullRomCurve3, Vector3 } from 'three'

const LAB_MIN = [0, -100, -100]
const LAB_MAX = [100, 100, 100]

const refX = 0.95047 // ref_X =  95.047   Observer= 2°, Illuminant= D65
const refY = 1.0 // ref_Y = 100.000
const refZ = 1.08883 // ref_Z = 108.883

function pivot_lab2xyz(n) {
  return n > 0.206893034 ? Math.pow(n, 3) : (n - 16 / 116) / 7.787
}

function pivot_xyz2lab(n) {
  return n > 0.008856 ? Math.pow(n, 1 / 3) : 7.787 * n + 16 / 116
}

function rgb2xyz([r, g, b]) {
  // gamma correction, see https://en.wikipedia.org/wiki/SRGB#The_reverse_transformation
  r = correctGamma_rgb2xyz(r / 255)
  g = correctGamma_rgb2xyz(g / 255)
  b = correctGamma_rgb2xyz(b / 255)

  // Observer. = 2°, Illuminant = D65
  return [
    r * 0.4124 + g * 0.3576 + b * 0.1805,
    r * 0.2126 + g * 0.7152 + b * 0.0722,
    r * 0.0193 + g * 0.1192 + b * 0.9505
  ]
}

function correctGamma_rgb2xyz(n) {
  return n > 0.04045 ? Math.pow((n + 0.055) / 1.055, 2.4) : n / 12.92
}

function xyz2lab([x, y, z]) {
  x = pivot_xyz2lab(x / refX)
  y = pivot_xyz2lab(y / refY)
  z = pivot_xyz2lab(z / refZ)

  if (116 * y - 16 < 0) throw new Error('Invalid input for XYZ')
  return [Math.max(0, 116 * y - 16), 500 * (x - y), 200 * (y - z)]
}

function rgb2lab(rgb) {
  rgb = rgb.map((n) => inRange0to255Rounded(n))
  const xyz = rgb2xyz(rgb)
  return xyz2lab(xyz)
}

function lab2xyz([L, a, b]) {
  const y = (L + 16) / 116
  const x = a / 500 + y
  const z = y - b / 200

  return [
    refX * pivot_lab2xyz(x),
    refY * pivot_lab2xyz(y),
    refZ * pivot_lab2xyz(z)
  ]
}

function lab2rgb(lab, scaled = true) {
  lab = lab.map((n, i) => {
    return Math.max(LAB_MIN[i], Math.min(LAB_MAX[i], n))
  })
  const xyz = lab2xyz(lab)
  return xyz2rgb(xyz, scaled)
}

// // gamma correction, see https://en.wikipedia.org/wiki/SRGB#The_reverse_transformation
function correctGamma_xyz2rgb(n) {
  return n > 0.0031308 ? 1.055 * Math.pow(n, 1 / 2.4) - 0.055 : 12.92 * n
}

function inRange0to255Rounded(n) {
  n = Math.round(n)
  if (n > 255) n = 255
  else if (n < 0) n = 0
  return n
}

function xyz2rgb([x, y, z], scaled = true) {
  // Observer. = 2°, Illuminant = D65
  const r = correctGamma_xyz2rgb(x * 3.2406 + y * -1.5372 + z * -0.4986)
  const g = correctGamma_xyz2rgb(x * -0.9689 + y * 1.8758 + z * 0.0415)
  const b = correctGamma_xyz2rgb(x * 0.0557 + y * -0.204 + z * 1.057)

  if (scaled) {
    return [
      inRange0to255Rounded(r * 255),
      inRange0to255Rounded(g * 255),
      inRange0to255Rounded(b * 255)
    ]
  } else {
    return [r, g, b]
  }
}

const sketch = () => {
  return ({ context, width, height, rgb, hex, rotate }) => {
    if (width === 0 || height === 0) return
    context.fillStyle = 'white'
    context.fillRect(0, 0, width, height)

    // your stepped color data, to be filled in
    const colorsAsHexList = hex
    const colorsAsLabList = rgb.map((c) => rgb2lab(c))

    const grd = !rotate
      ? context.createLinearGradient(0, 0, 0, height)
      : context.createLinearGradient(0, 0, width, 0)
    colorsAsHexList.forEach((hex, i, list) => {
      const t = i / (list.length - 1)
      grd.addColorStop(t, hex)
    })
    context.fillStyle = grd
    context.fillRect(0, 0, width, height)

    const img = context.createImageData(width, height)
    // draw curve
    const labPositions = colorsAsLabList.map((lab) => {
      return new Vector3().fromArray(lab)
    })
    const curve = new CatmullRomCurve3(labPositions)
    curve.curveType = 'catmullrom'

    // can play with tension to make sharper or softer gradients
    curve.tension = 0.5

    // uncomment to make a seamless gradient that wraps around
    // curve.closed = true

    const samples = getCurveDivisions(curve, img.width, false).map((p) =>
      p.toArray()
    )
    for (let y = 0; y < img.height; y++) {
      for (let x = 0; x < img.width; x++) {
        const i = x + y * img.width
        const [R, G, B] = lab2rgb(samples[x])
        img.data[i * 4 + 0] = R
        img.data[i * 4 + 1] = G
        img.data[i * 4 + 2] = B
        img.data[i * 4 + 3] = 255
      }
    }
    context.putImageData(img, 0, height)
  }

  function getCurveDivisions(curve, n, spaced = false) {
    const points = []
    for (let i = 0; i < n; i++) {
      const t = curve.closed ? i / n : i / (n - 1)
      let p = spaced ? curve.getPointAt(t) : curve.getPoint(t)
      points.push(p)
    }
    return points
  }
}

export default sketch()
