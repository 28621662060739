/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { useGesture } from 'react-use-gesture'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'

import useWindowSize from '@hooks/useWindowSize'
import { useStore } from '@stores/useStore'
import drawSplineGradient from '@utils/splineGradient'
import { Navigation, NavButton } from '@components/Navigation'
import Header from '@components/Header'
import { Debug } from '@components/Debug'

export const splitLine = (a, b, count) => {
  count = count + 1
  const d = Math.hypot(a[0] - b[0], a[1] - b[1]) / count
  const fi = Math.atan2(b[1] - a[1], b[0] - a[0])

  const points = []

  for (let i = 0; i <= count; i++) {
    points.push([a[0] + i * d * Math.cos(fi), a[1] + i * d * Math.sin(fi)])
  }

  return points
}

const getCoord = (point, offset) => {
  return [point[0] - offset[0], point[1] - offset[1]]
}

const Canvas = observer(() => {
  const room = useStore()
  const link = React.useRef(null)
  const context = React.useRef(null)
  const overlay = React.useRef(null)
  const canvas = React.useRef(null)
  const colorCanvas = React.useRef(null)
  const [active, setActive] = React.useState(false)
  const [lineWidth, setLineWidth] = React.useState(3)
  const [offset, setOffset] = React.useState([0, 0])
  const [showColors, setShowColors] = React.useState(false)
  const [finished, setFinished] = React.useState(false)
  const { width: windowW, height: windowH } = useWindowSize()

  const [firstRender, setFirstRender] = React.useState(true)

  React.useEffect(() => {
    canvas.current.width = room.width * room.pixelRatio
    canvas.current.height = room.height * room.pixelRatio
    canvas.current.style.width = `${room.width}px`
    overlay.current.style.height = `${room.height}px`
    overlay.current.style.width = `${room.width}px`
    canvas.current.style.height = `${room.height}px`
    colorCanvas.current.width = room.width * room.pixelRatio
    colorCanvas.current.height = room.height * room.pixelRatio
    colorCanvas.current.style.width = `${room.width}px`
    colorCanvas.current.style.height = `${room.height}px`
    setOffset([
      (windowW - canvas.current.width / room.pixelRatio) / 2,
      (windowH - canvas.current.height / room.pixelRatio) / 2
    ])

    const ctx = colorCanvas.current.getContext('2d')
    drawSplineGradient({
      context: ctx,
      hex: [
        '#ff0000',
        '#ff00ff',
        '#0000ff',
        '#00ffff',
        '#00ff00',
        '#ffff00',
        '#ff0000'
      ],
      rgb: [
        [255, 0, 0],
        [255, 0, 255],
        [0, 0, 255],
        [0, 255, 255],
        [0, 255, 0],
        [255, 255, 0],
        [255, 0, 0]
      ],
      rotate: room.width / room.height > 1,
      width: room.width * room.pixelRatio,
      height: room.height * room.pixelRatio
    })
    const bwGradient =
      room.width / room.height > 1
        ? ctx.createLinearGradient(0, 0, 0, room.height * room.pixelRatio)
        : ctx.createLinearGradient(0, 0, room.width * room.pixelRatio, 0)
    bwGradient.addColorStop(0, 'rgba(255, 255, 255, 1)')
    bwGradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)')
    bwGradient.addColorStop(0.5, 'rgba(0,     0,   0, 0)')
    bwGradient.addColorStop(1, 'rgba(0,     0,   0, 1)')
    ctx.fillStyle = bwGradient
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  }, [room.width, room.height, room.pixelRatio])

  React.useEffect(() => {
    setOffset([
      (windowW - canvas.current.width / room.pixelRatio) / 2,
      (windowH - canvas.current.height / room.pixelRatio) / 2
    ])
  }, [windowW, windowH, room.pixelRatio])

  const reset = () => {
    setActive(false)
    const lastColor = context.current.fillStyle
    context.current.fillStyle = 'white'
    context.current.fillRect(
      0,
      0,
      room.width * room.pixelRatio,
      room.height * room.pixelRatio
    )
    context.current.beginPath()
    context.current.fillStyle = lastColor
    room.sendMessage({
      type: 'reset'
    })
  }

  const handleMouseMove = (e) => {
    if (!active) return
    drawOnCanvas(e)
  }

  const handleMouseDown = (e) => {
    if (finished) {
      reset()
      setFinished(false)
    }
    if (firstRender) setFirstRender(false)
    setActive(true)
    overlay.current.style.opacity = '1'
  }

  const hideOverlay = debounce(
    () => {
      if (!overlay.current) return
      overlay.current.style.opacity = '0'
    },
    350,
    {
      trailing: true
    }
  )

  const handleMouseUp = () => {
    setActive(false)
  }

  React.useEffect(() => {
    context.current = canvas.current.getContext('2d')
    context.current.lineCap = 'round'
    context.current.lineJoin = 'round'
    context.current.miterLimit = 4
    room.setContext(context.current)
    reset()
  }, [])

  const drawOnCanvas = (e) => {
    if (e.first || e.last) return

    const ctx = context.current
    const distance = Math.hypot(
      e.xy[0] - e.previous[0],
      e.xy[1] - e.previous[1]
    )
    const points = splitLine(
      getCoord(e.xy, offset),
      getCoord(e.previous, offset),
      Math.max(1, distance / (lineWidth / 5))
    )
    room.sendMessage({
      type: 'line',
      start: getCoord(e.previous, offset),
      end: getCoord(e.xy, offset),
      size: lineWidth,
      color: context.current.fillStyle,
      step: Math.max(1, distance / (lineWidth / 5))
    })
    points.forEach((p) => {
      ctx.beginPath()
      ctx.arc(
        p[0] * room.pixelRatio,
        p[1] * room.pixelRatio,
        lineWidth,
        0,
        2 * Math.PI
      )
      ctx.fill()
      ctx.closePath()
    })
  }

  const bind = useGesture(
    {
      onDrag: (state) => handleMouseMove(state),
      onDragStart: (state) => handleMouseDown(state),
      onDragEnd: (state) => handleMouseUp(state)
    },
    {
      eventOptions: {
        pointer: false
      }
    }
  )

  const colorBind = useGesture(
    {
      onDragEnd: (e) => {
        const ctx = colorCanvas.current.getContext('2d')
        const coord = getCoord(e.xy, offset)
        const [r, g, b, a] = ctx.getImageData(
          coord[0] * room.pixelRatio,
          coord[1] * room.pixelRatio,
          1,
          1
        ).data
        context.current.strokeStyle = `rgb(${r}, ${g}, ${b})`
        context.current.fillStyle = `rgb(${r}, ${g}, ${b})`
        room.sendMessage({
          type: 'color',
          color: `rgb(${r}, ${g}, ${b})`
        })
        setShowColors(false)
      }
    },
    {
      eventOptions: {
        pointer: false
      }
    }
  )

  const handlers = React.useMemo(() => (room.user.initiator ? bind() : []), [
    room.user.initiator
  ])

  const colorHandlers = React.useMemo(
    () => (room.user.initiator ? colorBind() : []),
    [room.user.initiator]
  )

  return (
    <React.Fragment>
      <canvas ref={canvas} {...handlers} />

      <div
        ref={overlay}
        sx={{
          bg: 'black',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0,
          position: 'absolute',
          pointerEvents: 'none'
        }}
      />

      <Debug>
        <button onClick={() => reset()}>
          <span role="img" aria-label="reset">
            🔄
          </span>
        </button>
      </Debug>

      <a href="" download ref={link} style={{ visibility: 'hidden' }} />
      <Navigation
        show={!active}
        sx={{
          gridTemplateColumns: finished
            ? 'repeat(2, max-content)'
            : 'repeat(3, 1fr)'
        }}
      >
        {room.user.initiator && (
          <NavButton
            onClick={(ev) => {
              ev.stopPropagation()
              setShowColors(true)
            }}
            sx={{
              bg: context.current ? context.current.fillStyle : 'black',
              mixBlendMode: 'normal',
              color: 'white'
            }}
          >
            Color
          </NavButton>
        )}
        {!finished && !firstRender && room.user.initiator && (
          <NavButton
            onClick={() => {
              hideOverlay()
              setFinished(true)
            }}
            sx={{ justifySelf: 'center' }}
          >
            Finished
          </NavButton>
        )}
        {finished && (
          <NavButton
            onClick={() => {
              link.current.setAttribute('download', 'InvisPaint-drawing.png')
              const img = canvas.current
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream')
              link.current.setAttribute('href', img)
              link.current.click()
            }}
          >
            Save
          </NavButton>
        )}
      </Navigation>

      <canvas
        ref={colorCanvas}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: showColors ? 1 : 0,
          pointerEvents: showColors ? 'all' : 'none'
        }}
        {...colorHandlers}
      />

      <Header show={!active} />
    </React.Fragment>
  )
})

export default Canvas
